import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table";
import Layout from "ps-components/Layout/Layout.jsx";
import Card from "components/Card/Card.jsx";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";

const WithDrawnPolicy = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var clrImage = Images[1];
  var sno = 1;
  var tableHead = [`S.No`, `Product Name`, `UIN`, `Date of Withdrawn`];
  var tableRows = data.allStrapiInsuranceplans.edges.map(document => {
    var temp = [];
    temp.push(sno);
    temp.push(document.node.name);
    temp.push(document.node.irdaiId);
    temp.push(document.node.withdrawnDate);
    sno++;
    return temp;
  });
  var content = (
    <GridContainer>
      <GridItem
        xs={12}
        sm={10}
        md={10}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.textCenter
        )}
      >
        <Card>
          <Table striped tableHead={tableHead} tableData={tableRows} />
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <Layout
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Withdrawn Products</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/withdrawn-products"
          />
          <meta name="title" content="Withdrawn Products" />
          <meta name="twitter:title" content="Withdrawn Products" />
          <meta
            name="description"
            content="LIST OF PRODUCTS WITHDRAWN S.NO Product Name UIN Date of Withdrawn 1 Medi Premier Insurance Policy IRDA/NL-HLT/SHAI/P-H/V.I/174/13-14 29.01.2016 2"
          />
          <meta property="og:title" content="Withdrawn Products" />
          <meta property="og:description" content="LIST OF PRODUCTS WITHDRAWN S.NO Product Name UIN Date of Withdrawn 1 Medi Premier Insurance Policy IRDA/NL-HLT/SHAI/P-H/V.I/174/13-14 29.01.2016 2" />
          <meta property="og:url" content="https://www.starhealth.in/withdrawn-products"/>
          <meta name="Withdrawn Products" />
          <meta name="twitter:description" content="LIST OF PRODUCTS WITHDRAWN S.NO Product Name UIN Date of Withdrawn 1 Medi Premier Insurance Policy IRDA/NL-HLT/SHAI/P-H/V.I/174/13-14 29.01.2016 2" />
          <meta property="twitter:url" content="https://www.starhealth.in/withdrawn-products" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100, padding: `70px 0px` }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <div>
                  <h2 className={classes.title}>List of Withdrawn Products.</h2>
                </div>
              </GridItem>
              <GridItem>{content}</GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
WithDrawnPolicy.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(customStaticPageStyle)(WithDrawnPolicy);

export const aboutUsQuery = graphql`
  query WithdrawnPolicy {
    allStrapiInsuranceplans(
      filter: { isWithdrawn: { eq: true } }
      sort: { fields: withdrawnDate, order: DESC }
    ) {
      edges {
        node {
          id
          name
          irdaiId
          withdrawnDate(formatString: "DD-MM-YYYY")
        }
      }
    }
    allStrapiImages(
      filter: { section: { category: { eq: "withdrawn_products" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
